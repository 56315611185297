import gql from 'graphql-tag';
import workflowSearchConditionFragment from './workflowSearchCondition';

export default gql`
  fragment workflowFragment on Workflow {
    id
    uuid
    name
    status
    statusText
    actionsStatusOpenCount
    actionsStatusCompletedCount
    actionsStatusCanceledCount
    dueDay
    target
    targetText
    createdAt
    updatedAt
    prospectsStatusOpenCount
    prospectsStatusClosedCount
    searchCondition {
      ...workflowSearchConditionFragment
    }
  }
  ${workflowSearchConditionFragment}
`;
