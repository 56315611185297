import gql from 'graphql-tag';

export default gql`
  fragment salesforceUserFragment on SalesforceUser {
    id
    username
    lastName
    firstName
    name
    companyName
    userId
  }
`;
