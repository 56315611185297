import React from 'react';
import {Route, Switch, Redirect} from 'react-router';
import Password from './password';
import Team from './team';
import Users from './users';
import Externals from './externals';
import Share from './share';
import CustomizeItem from './customizeItem';
import Status from './status';
import Pools from './pools';
import Mails from './mails';
import Plan from './plan';
import Notifications from './notifications';
import Phase from './phase';

export default () => {
  return (
    <Switch>
      <Route path="/settings/password" component={Password} />
      <Route path="/settings/teams" component={Team} />
      <Route path="/settings/users" component={Users} />
      <Route path="/settings/externals" component={Externals} />
      <Route path="/settings/share" component={Share} />
      <Route path="/settings/customize_item" component={CustomizeItem} />
      <Route path="/settings/pools" component={Pools} />
      <Route path="/settings/status" component={Status} />
      <Route path="/settings/mails" component={Mails} />
      <Route path="/settings/plan" component={Plan} />
      <Route path="/settings/notifications" component={Notifications} />
      <Route path="/settings/phase" component={Phase} />
      <Redirect to="/settings/externals" />
    </Switch>
  );
};
