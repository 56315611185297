import React from 'react';
import {Route, Switch} from 'react-router-dom';
import List from './List';
import Call from './call';
import Deal from './deal';
import Lead from './lead';
import LeadRegistration from './lead_registration';
import CollectedForm from './collected_form';
import Todo from './todo';
import Maintenance from './maintenance';

export default () => {
  return (
    <Switch>
      <Route path="/reports/call" component={Call} />
      <Route path="/reports/deal" component={Deal} />
      <Route path="/reports/lead" component={Lead} />
      <Route path="/reports/lead_registration" component={LeadRegistration} />
      <Route path="/reports/collected_form" component={CollectedForm} />
      <Route path="/reports/todo" component={Todo} />
      <Route path="/reports/maintenance" component={Maintenance} />
      <Route path="/reports" component={List} />
    </Switch>
  );
};
