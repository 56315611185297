import React from 'react';
import styled from 'styled-components';
import {
  useUpdateWorkflowMutation,
  Workflow,
  useStartWorkflowMutation,
  useStopWorkflowMutation,
  AutomaticMailStep,
} from 'api';
import {Button, Input} from 'components/antd';
import {useParams} from 'react-router';
import useClientUser from 'hooks/useClientUser';

interface Props {
  workflow: Workflow;
}

export default ({workflow}: Props) => {
  const {isAdmin, isMember} = useClientUser();
  const invalidAutomaticMailStep = workflow?.steps
    .filter((step) => step.steppable.__typename === 'AutomaticMailStep')
    ?.filter((step: {steppable: AutomaticMailStep}) => {
      const {automaticMail} = step.steppable;
      return (
        !automaticMail.fromEmail ||
        !automaticMail.subject ||
        !automaticMail.bodyText ||
        !automaticMail.bodyHtml
      );
    });

  // TODO: Workflowのバリデーション考える
  const isValidWorkflow = React.useMemo(
    () =>
      workflow?.status === 'draft' &&
      workflow?.steps.find((step) => step.status === 'open') &&
      invalidAutomaticMailStep.length == 0,
    [workflow],
  );

  const options = location.pathname.endsWith('/steps')
    ? {
        variables: {uuid: workflow?.uuid},
        refetchQueries: ['actionCountsByWorkflow'],
      }
    : {variables: {uuid: workflow?.uuid}};

  const [start, {loading}] = useStartWorkflowMutation(options);

  const [stop, {loading: stopLoading}] = useStopWorkflowMutation(options);

  const {workflowId} = useParams<{workflowId: string}>();

  const [name, setName] = React.useState(workflow.name);

  const [updateWorkflow] = useUpdateWorkflowMutation();

  const updateName = (e: any) => {
    if (e.target.value) {
      updateWorkflow({
        variables: {
          uuid: workflowId,
          attributes: {
            name: e.target.value,
          },
        },
      });
    } else {
      alert('ワークフロー名は必須です。');
      setName(workflow.name);
    }
  };

  return (
    <Container>
      <div>
        <Name
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={updateName}
          onPressEnter={updateName}
          disabled={!isMember}
        />
      </div>
      {isAdmin && (
        <div>
          {workflow.status === 'open' ? (
            <StopButton
              htmlType="button"
              loading={stopLoading}
              onClick={() => confirm('本当に停止しますか？') && stop()}>
              停止
            </StopButton>
          ) : (
            <CustomButton
              htmlType="button"
              type="primary"
              disabled={!isValidWorkflow}
              loading={loading}
              onClick={() => confirm('本当に開始しますか？') && start()}>
              開始
            </CustomButton>
          )}
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;

  & > div {
    display: flex;
    align-items: center;
    &:first-child {
      width: 100%;
    }
    div:first-child {
      margin-right: 15px;
    }
  }
`;

const CustomButton = styled(Button)`
  height: 36px;
  width: 96px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 1rem;
`;

const StopButton = styled(CustomButton)`
  color: #68b5fb;
  border: 1px solid #68b5fb;
  background: transparent;
`;

const Name = styled(Input)`
  font-size: 20px;
  font-weight: bold;
  margin: 1rem 0;
  padding-left: 0;
  border-color: transparent;
  background-color: transparent;
  border-radius: 4px;

  &:hover {
    border: 1px solid #bdc4cb;
  }
`;
