import React from 'react';
import useSearchParams from '../useSearchParams';
import {DatePicker} from 'components/antd';
import {Box} from './style';
import moment from 'moment';

const DisplayRangeFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      {(location.pathname.includes('report/all') ||
        location.pathname.includes('report/monthly')) && (
        <Box>
          <DatePicker
            picker="year"
            format={'YYYY年'}
            placeholder={'表示期間'}
            value={moment(query.date, 'YYYY')}
            onChange={(value) => {
              if (value) {
                setQuery({
                  date: moment(value).startOf('month').format('YYYY-MM-DD'),
                });
              } else {
                setQuery({
                  date: moment().startOf('month').format('YYYY-MM-DD'),
                });
              }
            }}
          />
        </Box>
      )}
      {(location.pathname.includes('report/weekly') ||
        location.pathname.includes('report/daily')) && (
        <Box>
          <DatePicker
            picker="month"
            format={'YYYY年MM月'}
            placeholder={'表示期間'}
            value={moment(query.date, 'YYYY-MM')}
            onChange={(value) => {
              if (value) {
                setQuery({
                  date: moment(value).startOf('month').format('YYYY-MM-DD'),
                });
              } else {
                setQuery({
                  date: moment().startOf('month').format('YYYY-MM-DD'),
                });
              }
            }}
          />
        </Box>
      )}
    </>
  );
};

export default DisplayRangeFilter;
