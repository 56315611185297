import React from 'react';
import {useParams} from 'react-router-dom';
import {useTable} from 'react-table';
import Pagination from '../Pagination';
import Cell from './Cell';
import {useAccountPoolsQuery, AccountPool} from 'api';
// @ts-ignore
import useSearchParams from './useSearchParams';

export default () => {
  const {poolId} = useParams<{poolId: string}>();
  const {page, perPage, setQuery, searchParams} = useSearchParams();
  const {data: {accountPools: {accountPools = [], pagination = {}} = {}} = {}} =
    useAccountPoolsQuery({
      variables: {
        uuid: poolId,
        page: page,
        perPage: perPage,
        search: searchParams,
      },
      fetchPolicy: 'cache-and-network',
    });

  const columns: any = React.useMemo(
    () => [
      {
        id: 'name',
        Header: '企業',
        Cell: Cell.Name,
      },
      {
        id: 'accountGroup',
        Header: 'リスト名',
        Cell: Cell.AccountGroup,
      },
      {
        id: 'stats',
        Header: '',
        Cell: Cell.Stats,
        style: {width: '192px'},
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable<AccountPool>({columns, data: accountPools || []});

  return (
    <div className="flex flex-col items-center">
      <table
        className="h-full w-full border-separate border-spacing-0 table-fixed"
        {...getTableProps()}
        cellSpacing="0"
        cellPadding="0">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => {
                return (
                  <th
                    className="py-2 pl-4 text-xs text-c-light text-left border-b border-c-border first-of-type:pl-0"
                    {...column.getHeaderProps([
                      {
                        // className: column.className,
                        // @ts-ignore
                        style: column.style,
                      },
                    ])}
                    key={j}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="text-base" {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                className="box-border bg-white"
                {...row.getRowProps()}
                key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td
                      className="align-top min-w-[3rem] m-0 whitespace-nowrap overflow-hidden text-ellipsis border-b border-c-border py-5 first-of-type:pl-0"
                      {...cell.getCellProps()}
                      key={j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        size="small"
        showSizeChanger={false}
        showQuickJumper={false}
        total={pagination.totalCount}
        current={pagination.currentPage}
        pageSize={perPage}
        onChange={(page) => setQuery({page})}
      />
    </div>
  );
};
