import React from 'react';
import {useParams} from 'react-router-dom';
import {Menu, Transition} from '@headlessui/react';
import useProject from 'hooks/useProject';
import useClientUser from 'hooks/useClientUser';
import {DotMenu} from 'components/Ui/Icon';
import DeleteDuplicateModal from './DeleteDuplicateModal';
import CopyModal from './CopyModal';
import BlockGroupModal from './BlockGroupModal';
import LetterModal from './LetterModal';
import AssignUserModal from './AssignUserModal';
import ApproachModal from './ApproachModal';
import * as Icon from './Icon';

const MenuItem: React.FC<{onClick: () => void}> = ({children, onClick}) => (
  <Menu.Item>
    <span
      onClick={onClick}
      className="py-2 px-4 cursor-pointer flex items-center gap-2 font-bold text-c-lighter hover:text-c-primary">
      {children}
    </span>
  </Menu.Item>
);

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {isOwnProject} = useProject(projectId);
  const {isAdmin} = useClientUser();

  const [deleteDuplicateModalVisible, setDeleteDuplicateModalVisible] =
    React.useState(false);
  const [copyModalVisible, setCopyModalVisible] = React.useState(false);
  const [blockGroupModalVisible, setBlockGroupModalVisible] =
    React.useState(false);
  const [letterModalVisible, setLetterModalVisible] = React.useState(false);
  const [assignUserModalVisible, setAssignUserModalVisible] =
    React.useState(false);
  const [approachModalVisible, setApproachModalVisible] = React.useState(false);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="inline-flex w-full justify-center bg-transparent px-2 py-1 text-sm focus:outline-none cursor-pointer">
          <DotMenu />
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-1 w-48 origin-top-right py-2 rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
            {isOwnProject && (
              <>
                <MenuItem onClick={() => setDeleteDuplicateModalVisible(true)}>
                  <Icon.DeleteDuplicate className="w-5" />
                  重複削除
                </MenuItem>
                <MenuItem onClick={() => setCopyModalVisible(true)}>
                  <Icon.Copy className="w-5" />
                  コピー
                </MenuItem>
                <MenuItem onClick={() => setBlockGroupModalVisible(true)}>
                  <Icon.BlockGroup className="w-5" />
                  ブロックグループ
                </MenuItem>
              </>
            )}
            <MenuItem onClick={() => setLetterModalVisible(true)}>
              <Icon.Letter className="w-5" />
              手紙
            </MenuItem>
            {isOwnProject && (
              <MenuItem onClick={() => setAssignUserModalVisible(true)}>
                <Icon.AssignUser className="w-5" />
                割り当て
              </MenuItem>
            )}
            {isAdmin && isOwnProject && (
              <>
                <hr className="border-t border-t-c-border mx-4 my-2" />
                <MenuItem onClick={() => setApproachModalVisible(true)}>
                  <Icon.Approach className="w-5" />
                  アプローチ一括作成
                </MenuItem>
              </>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
      <DeleteDuplicateModal
        isModalVisible={deleteDuplicateModalVisible}
        setModalVisible={setDeleteDuplicateModalVisible}
      />
      <CopyModal
        isModalVisible={copyModalVisible}
        setModalVisible={setCopyModalVisible}
      />
      <BlockGroupModal
        isModalVisible={blockGroupModalVisible}
        setModalVisible={setBlockGroupModalVisible}
      />
      <LetterModal
        isModalVisible={letterModalVisible}
        setModalVisible={setLetterModalVisible}
      />
      {assignUserModalVisible && (
        <AssignUserModal
          isModalVisible={assignUserModalVisible}
          setModalVisible={setAssignUserModalVisible}
        />
      )}
      <ApproachModal
        visible={approachModalVisible}
        onClose={() => setApproachModalVisible(false)}
      />
    </>
  );
};
