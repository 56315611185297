import React from 'react';
import Modal from './Modal';
import {useParams} from 'react-router-dom';
import {
  useProspectPoolsQuery,
  useUsersByClientAllQuery,
  useCreateProspectPoolUserUpdateMutation,
} from 'api';
import {Combobox, Transition} from '@headlessui/react';
import {ChevronUpDownIcon} from '@heroicons/react/20/solid';
import useSearchParams from '../useSearchParams';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const kanaToHira = (str: string) =>
  str.replace(/[\u30a1-\u30f6]/g, (match) => {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });

const AssignModal = ({visible, onClose}: Props) => {
  const {poolId} = useParams<{poolId: string}>();
  const [userId, setUserId] = React.useState(null);
  const [keyword, setKeyword] = React.useState('');
  const inputRef = React.useRef<HTMLInputElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const {page, perPage, searchParams} = useSearchParams();

  const handleClose = () => {
    setUserId(null);
    setKeyword('');
    onClose();
  };

  const [createProspectPoolUserUpdateMutation, {loading}] =
    useCreateProspectPoolUserUpdateMutation({
      onCompleted: handleClose,
    });
  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  const {data: {prospectPools: {pagination = {}} = {}} = {}} =
    useProspectPoolsQuery({
      variables: {
        uuid: poolId,
        page: page,
        perPage: perPage,
        search: searchParams,
      },
      fetchPolicy: 'cache-only',
      skip: !poolId,
    });

  const filteredUsers = React.useMemo(
    () =>
      users
        ?.filter((user) => user.id !== userId)
        ?.filter((user) =>
          kanaToHira(`${user.lastName}${user.firstName}`).includes(
            kanaToHira(keyword),
          ),
        ),
    [users, keyword, userId],
  );

  const selectedUser = React.useMemo(
    () => users.find((user) => user.id === userId),
    [users, userId],
  );

  return (
    <Modal title="リード担当者一括更新" visible={visible} onClose={handleClose}>
      <div className="flex flex-col gap-4">
        <div className="flex">
          <div className="bg-c-bg h-8 flex items-center px-4 gap-2 rounded">
            <span className="text-sm">対象リード数:</span>
            <span className="text-base font-bold">
              {(pagination?.totalCount || 0).toLocaleString()}件
            </span>
          </div>
        </div>
        <Combobox
          value={userId}
          onChange={(userId) => {
            setKeyword('');
            setUserId(userId);
          }}>
          <Combobox.Button as={React.Fragment}>
            <div
              className="relative mt-1"
              onClick={() => inputRef.current?.focus()}>
              <div className="relative w-full min-h-[2.5rem] cursor-default rounded-sm bg-white py-2 pl-2 pr-10 flex items-center gap-1 border border-c-border focus:outline-none flex-wrap text-center">
                {(selectedUser?.firstName || selectedUser?.lastName) && (
                  <span
                    className="truncate inline-flex items-center justify-center pl-2 pr-1 py-1 text-sm font-medium leading-4 rounded-sm text-center"
                    onClick={(e) => e.stopPropagation()}>
                    {selectedUser?.lastName} {selectedUser?.firstName}
                  </span>
                )}
                <Combobox.Input
                  className="flex-1 outline-none border-transparent focus:border-transparent focus:ring-0"
                  onChange={(e) => setKeyword(e.target.value)}
                  onFocus={() => buttonRef.current?.click()}
                  displayValue={() => keyword}
                  ref={inputRef}
                />
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </div>
              {filteredUsers.length > 0 && (
                <Transition
                  as={React.Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredUsers.map((user) => (
                      <Combobox.Option
                        key={user.id}
                        className={({active}) =>
                          `relative cursor-default select-none py-2 px-4 ${
                            active ? 'bg-c-bg' : 'white'
                          }`
                        }
                        value={user.id}>
                        <div className="flex items-center gap-2">
                          <span className="h-3 w-3 rounded-sm bg" />
                          <span className="truncate flex-1">
                            {user.lastName} {user.firstName}
                          </span>
                        </div>
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </Transition>
              )}
            </div>
          </Combobox.Button>
        </Combobox>
        <div className="flex justify-end">
          <button
            type="button"
            disabled={!userId || loading}
            onClick={() => {
              createProspectPoolUserUpdateMutation({
                variables: {
                  poolUuid: poolId,
                  targetUserId: userId,
                  searchCondition: searchParams,
                },
                refetchQueries: ['pool', 'prospectPools'],
              });
            }}
            className="cursor-pointer bg-c-primary text-white border-none text-sm hover:opacity-50 rounded items-center justify-center h-9 w-32 disabled:bg-c-bg disabled:text-c-light">
            一括更新
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AssignModal;
