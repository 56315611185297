import React from 'react';
import {
  ClientPreleadCustomizeItem,
  Prelead,
  useUpdatePreleadCustomizeItemSelectMutation,
} from 'api';
import {Select as UISelect} from 'components/antd';
import styled from 'styled-components';
import useClientUser from 'hooks/useClientUser';

interface Props {
  prelead: Prelead;
  clientPreleadCustomizeItem: ClientPreleadCustomizeItem;
}

export default ({prelead, clientPreleadCustomizeItem}: Props) => {
  const {isAdmin} = useClientUser();
  const [updateCustomizeItem] = useUpdatePreleadCustomizeItemSelectMutation();

  const customizeItem = prelead.customizeItemSelects.find(
    (item) =>
      item.clientPreleadCustomizeItemId === clientPreleadCustomizeItem.id,
  );

  const tags = customizeItem
    ? clientPreleadCustomizeItem.selectOptions
        .filter(
          (option) =>
            customizeItem?.clientPreleadCustomizeItemSelectOptionIds.includes(
              option.id,
            ),
        )
        .map((option) => option.label)
    : [];

  const [values, setValues] = React.useState(tags);

  React.useEffect(() => {
    updateCustomizeItem({
      variables: {
        uuid: prelead.uuid,
        clientPreleadCustomizeItemId: clientPreleadCustomizeItem.id,
        values: values,
      },
      refetchQueries: ['clientPreleadCustomizeItems'],
    });
  }, [values]);

  return (
    <Select
      mode={isAdmin ? 'tags' : 'multiple'}
      style={{width: '100%'}}
      placeholder="未入力"
      value={tags}
      onChange={(value) => setValues(value as string[])}>
      {clientPreleadCustomizeItem.selectOptions.map((option) => (
        <Select.Option key={option.id} value={option.label}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

const Select = styled(UISelect)`
  .ant-select-selector {
    border-color: transparent !important;
    border-radius: 4px !important;
    width: 100%;
  }

  &:hover {
    border: 1px solid #bdc4cb;
  }
`;
