import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../components/Table/types';
import {useEmployeeReportsQuery} from 'api';
import employeeRangeOptions from 'helpers/employeeRangeOptions';
import React from 'react';

const useTableData = (): ReportTable => {
  const headers: ReportTableHeader[] = ['従業員数', ''];
  const {data: {employeeReports = []} = {}} = useEmployeeReportsQuery({});
  const necessaryEmployeeReports = employeeReports.slice(1);

  const reportData = React.useMemo(() => {
    return necessaryEmployeeReports.map((employeeReport) => {
      return {
        label: employeeRangeOptions.find(
          (employeeRangeOption) =>
            employeeRangeOption.value === employeeReport.name,
        )?.label,
        values: employeeReport.values,
      };
    });
  }, [necessaryEmployeeReports]);

  const data: ReportTableData[] = [...reportData].filter(Boolean);

  return {
    data,
    headers,
  };
};

export default useTableData;
