import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  Form,
  Input,
  Radio,
  DatePicker,
  TimePicker,
  Tooltip,
} from 'components/antd';
import {
  useUpdateMailCampaignMutation,
  MailCampaign,
  MailCampaignAttributes,
} from 'api';
import {useParams} from 'react-router';
import useDebounce from 'hooks/useDebounce';
import locale from 'antd/es/date-picker/locale/ja_JP';
import {Information} from 'components/Ui/Icon';

interface Props {
  mailCampaign: MailCampaign;
}

export default ({mailCampaign}: Props) => {
  const [updateMailCampaign] = useUpdateMailCampaignMutation({});
  const {mailCampaignId} = useParams<{mailCampaignId: string}>();

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    getFieldProps,
  } = useFormik({
    initialValues: {
      subject: mailCampaign?.subject || '',
      fromAddress: mailCampaign?.fromAddress || '',
      fromName: mailCampaign?.fromName || '',
      cc: mailCampaign?.cc || [],
      bcc: mailCampaign?.bcc || [],
      isImmediately: mailCampaign?.isImmediately || false,
      sendAt: mailCampaign?.sendAt || null,
      snippet: mailCampaign?.snippet || '',
    },
    validationSchema: Yup.object().shape({
      subject: Yup.string().required('必須項目です'),
      fromAddress: Yup.string()
        .trim()
        .email('不正なメールアドレスです')
        .required('必須項目です'),
      fromName: Yup.string().required('必須項目です'),
      cc: Yup.array()
        .ensure()
        .of(Yup.string().trim().email('不正なメールアドレスです'))
        .nullable(),
      bcc: Yup.array()
        .ensure()
        .of(Yup.string().trim().email('不正なメールアドレスです'))
        .nullable(),
    }),
    onSubmit: (values) =>
      updateMailCampaign({
        variables: {
          uuid: mailCampaignId,
          attributes: values,
        },
      }),
  });

  const debouncedValue = useDebounce<MailCampaignAttributes>(values, 100);

  React.useEffect(() => {
    updateMailCampaign({
      variables: {
        uuid: mailCampaignId,
        attributes: debouncedValue,
      },
    });
  }, [debouncedValue]);

  const disableUntilCurrentTimeToday = moment().format('YYYY-MM-DD') ===
    moment(values.sendAt).format('YYYY-MM-DD') && [
    ...Array(moment().hours() + 1).keys(),
  ];

  return (
    <Container>
      <Form layout="vertical">
        <Form.Item
          label="件名"
          required
          validateStatus={errors.subject ? 'error' : 'success'}
          help={errors.subject}>
          <Input
            type="text"
            name="subject"
            value={values.subject}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={mailCampaign?.status !== 'draft'}
          />
        </Form.Item>
        <Form.Item
          label="差出人メールアドレス"
          required
          validateStatus={errors.fromAddress ? 'error' : 'success'}
          help={errors.fromAddress}>
          <Input
            type="text"
            name="fromAddress"
            value={values.fromAddress}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={mailCampaign?.status !== 'draft'}
          />
        </Form.Item>
        <Form.Item
          label="差出人名"
          required
          validateStatus={errors.fromName ? 'error' : 'success'}
          help={errors.fromName}>
          <Input
            type="text"
            name="fromName"
            value={values.fromName}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={mailCampaign?.status !== 'draft'}
          />
        </Form.Item>
        <Form.Item
          label="Cc"
          validateStatus={errors.cc ? 'error' : 'success'}
          help={errors.cc}>
          <Input
            type="text"
            name="cc"
            value={values.cc.join(',')}
            onChange={(e: any) =>
              setFieldValue('cc', (e.target.value as string).split(','))
            }
            onBlur={handleBlur}
            disabled={mailCampaign?.status !== 'draft'}
          />
        </Form.Item>
        <Form.Item
          label="Bcc"
          validateStatus={errors.bcc ? 'error' : 'success'}
          help={errors.bcc}>
          <Input
            type="text"
            name="Bcc"
            value={values.bcc.join(',')}
            onChange={(e: any) =>
              setFieldValue('bcc', (e.target.value as string).split(','))
            }
            onBlur={handleBlur}
            disabled={mailCampaign?.status !== 'draft'}
          />
        </Form.Item>
        {/*
        <Form.Item
          label="プレビューテキスト"
          validateStatus={errors.snippet ? 'error' : 'success'}
          help={errors.snippet}>
          <Input
            type="text"
            name="snippet"
            value={values.snippet}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={mailCampaign?.status !== 'draft'}
          />
        </Form.Item>
        */}
      </Form>
      <Options>
        <Form layout="vertical">
          <Form.Item>
            <div style={{display: 'flex'}}>
              <span style={{fontWeight: 'bold', marginRight: '6px'}}>
                送信オプション
              </span>
              <Tooltip
                placement="top"
                title={'メールを送信する際、時間がかかることがあります。'}>
                <div>
                  <Information />
                </div>
              </Tooltip>
            </div>
            <Radio.Group
              {...getFieldProps('isImmediately')}
              disabled={mailCampaign?.status !== 'draft'}>
              <Form.Item style={{marginBottom: '0'}}>
                <Radio
                  value={true}
                  onClick={() => setFieldValue('sendAt', null)}
                />
                今すぐ送信
              </Form.Item>
              <Form.Item style={{marginBottom: '0.5rem'}}>
                <Radio value={false} />
                送信日時を予約
                <DatePicker
                  locale={locale}
                  disabled={
                    mailCampaign?.status !== 'draft' || values.isImmediately
                  }
                  value={values.sendAt ? moment(values.sendAt) : null}
                  allowClear={false}
                  style={{width: '120px', margin: '0 0.5rem'}}
                  format="YYYY/MM/DD"
                  showNow={false}
                  onChange={(date) =>
                    setFieldValue(
                      'sendAt',
                      date
                        ?.set('minute', 0)
                        .set('second', 0)
                        .set('millisecond', 0)
                        .add(1, 'hours'),
                    )
                  }
                  disabledDate={(current) =>
                    current &&
                    current < moment().endOf('day').subtract(1, 'day')
                  }
                />
                <TimePicker
                  locale={locale}
                  disabled={
                    mailCampaign?.status !== 'draft' || values.isImmediately
                  }
                  disabledHours={() => disableUntilCurrentTimeToday}
                  value={values.sendAt ? moment(values.sendAt) : null}
                  allowClear={false}
                  style={{width: '80px', margin: '0 0.5rem'}}
                  format="HH:00"
                  showNow={false}
                  onChange={(date) =>
                    setFieldValue(
                      'sendAt',
                      date
                        ?.set('minute', 0)
                        .set('second', 0)
                        .set('millisecond', 0),
                    )
                  }
                />
                に送信
              </Form.Item>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Options>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 25px;
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  form {
    flex: 1;
  }
`;

const Options = styled.div`
  width: 480px;
  background: #fafbfb;
  border-radius: 4px;
  padding: 2rem;
  svg {
    width: 18px;
    height: 18px;
  }
`;
