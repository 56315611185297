import React from 'react';
import {Filter} from 'components/Ui/Icon';
import Modal from './Modal';
import useSearchParams from '../useSearchParams';

export default () => {
  const [visible, setVisible] = React.useState(false);
  const {query} = useSearchParams();

  const numberOfLeadFilersInUse = [
    query.leadSourceIds?.length > 0,
    query.originalLeadSourceIds?.length > 0,
    query.stages?.length > 0,
    query.ranks?.length > 0,
    query.workflowIds?.length > 0,
    query.isUnsubscribed !== null,
    query.tagIds?.length > 0,
    query.excludedTagIds?.length > 0,
    query.assignees?.length > 0,
    query.mailCampaignId !== undefined && query.mailCampaignId !== null,
    query.mailCampaignUrlIds?.length > 0 &&
      (query.mailCampaignClickedFromDate || query.mailCampaignClickedToDate),
    query.isTargetAllMailCampaigns &&
      (query.mailCampaignOpenedFromDate ||
        query.mailCampaignOpenedToDate ||
        query.mailCampaignClickedFromDate ||
        query.mailCampaignClickedToDate),
    query.telStatuses?.length > 0,
    query.prospectCustomizeItemSearchConditions?.length > 0,
    query.trackedAtFrom || query.trackedAtTo,
    query.isBounced !== null,
    query.telUpdatedDateFrom || query.telUpdatedDateTo,
    query.inflowDateFrom || query.inflowDateTo,
    query.lastTelUserIds?.length > 0,
    query.isTargetAllWorkflows &&
      (query.workflowMailOpenedToDate ||
        query.workflowMailOpenedFromDate ||
        query.workflowMailClickedFromDate ||
        query.workflowMailClickedToDate),
    query.presenceTelNumber !== null,
    query.subCategories?.length > 0,
    query.listingMarkets?.length > 0,
    query.employeeNumber?.from || query.employeeNumber?.to,
    query.isExistTelActivity !== null,
    query.phases?.length > 0,
    query.capitalFund?.from || query.capitalFund?.to,
    query.cityIds?.length > 0,
    query.establishedDate?.from || query.establishedDate?.to,
    query.hasCompany !== null,
  ].filter(Boolean).length;

  const numberOfFormFilersInUse = [
    query.formIds?.length > 0,
    query.collectedFormUrlIds?.length > 0,
  ].filter(Boolean).length;

  const numberOfSalesforceFiltersInUse = [
    query.salesforceCampaignId,
    query.salesforceCampaignSearchConditions?.length > 0,
    query.salesforceDealRecordTypeId,
    query.salesforceDealSearchConditions?.length > 0,
    query.salesforceContactRecordTypeId,
    query.salesforceLeadRecordTypeId,
    query.salesforceLeadSearchConditions?.length > 0,
  ].filter(Boolean).length;

  const numberOfFiltersInUse =
    numberOfLeadFilersInUse +
    numberOfFormFilersInUse +
    numberOfSalesforceFiltersInUse;

  return (
    <>
      <div className="flex items-center gap-4">
        <button
          onClick={() => setVisible(true)}
          className="relative border border-c-border rounded w-9 h-9 bg-white text-c-lighti cursor-pointer flex items-center justify-center">
          <Filter color={numberOfFiltersInUse > 0 ? '#68B5FB' : '#BDC4CB'} />
          {numberOfFiltersInUse > 0 && (
            <span className="absolute right-[-5px] top-[-5px] rounded bg-c-primary min-w-[14px] h-[14px] flex items-center justify-center text-xs text-white">
              {numberOfFiltersInUse}
            </span>
          )}
        </button>
        {numberOfLeadFilersInUse > 0 && (
          <div className="flex items-center gap-1">
            <span className="text-sm">リード</span>
            <span className="text-xs bg-[#DDEFFF] text-c-primary rounded-full h-4 min-w-[16px] flex items-center justify-center">
              {numberOfLeadFilersInUse}
            </span>
          </div>
        )}
        {numberOfFormFilersInUse > 0 && (
          <div className="flex items-center gap-1">
            <span className="text-sm">フォーム</span>
            <span className="text-xs bg-[#DDEFFF] text-c-primary rounded-full h-4 min-w-[16px] flex items-center justify-center">
              {numberOfFormFilersInUse}
            </span>
          </div>
        )}
        {numberOfSalesforceFiltersInUse > 0 && (
          <div className="flex items-center gap-1">
            <span className="text-sm">Salesforce</span>
            <span className="text-xs bg-[#DDEFFF] text-c-primary rounded-full h-4 min-w-[16px] flex items-center justify-center">
              {numberOfSalesforceFiltersInUse}
            </span>
          </div>
        )}
      </div>
      <Modal visible={visible} onClose={() => setVisible(false)} />
    </>
  );
};
