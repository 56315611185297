import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../components/Table/types';
import {useProspectReportProspectPoolUserDailyCallUserReportsQuery} from 'api';
import {StringParam, useQueryParams} from 'use-query-params';
import useMonthHeaders from '../../hooks/useMonthHeaders';

const useTableData = (): ReportTable => {
  const [query] = useQueryParams({
    month: StringParam,
  });
  const {data: {prospectReportProspectPoolUserDailyCallUserReports = []} = {}} =
    useProspectReportProspectPoolUserDailyCallUserReportsQuery({
      variables: {month: query.month},
      skip: !query.month,
      fetchPolicy: 'cache-and-network',
    });

  const headers: ReportTableHeader[] = useMonthHeaders(query.month);
  const sum = (values: number[]) =>
    values.filter(Boolean).reduce((acc, cur) => acc + cur, null);

  const callData = () => {
    return prospectReportProspectPoolUserDailyCallUserReports.map((report) => {
      const reportValuesSum = sum(report.values);
      return {
        label: report.name,
        values: [reportValuesSum, ...report.values],
      };
    });
  };

  const data: ReportTableData[] = [...callData()];

  return {
    data,
    headers,
  };
};

export default useTableData;
