import React, {useState} from 'react';
import Modal from './Modal';
import styled from 'styled-components';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useUsersByClientAllQuery, useUpdateTaskMutation, Task, User} from 'api';
import {taskPriorities, taskStatuses} from 'helpers/task';
import {Quill} from 'components/Ui';
import {Combobox, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';

interface Props {
  visible: boolean;
  onClose: () => void;
  task: Task;
}

export default ({visible, onClose, task}: Props) => {
  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });
  const [user, setUser] = useState<User>(task.user);
  const [userName, setUserName] = useState('');
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const filteredUsers =
    userName === ''
      ? users
      : users?.filter((user) => {
          return `${user?.lastName} ${user?.firstName}`
            ?.toLowerCase()
            ?.includes(userName?.toLowerCase());
        });

  const [update] = useUpdateTaskMutation({
    onCompleted: onClose,
    refetchQueries: ['taskCounts'],
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: task?.title || '',
      status: task?.status || 'not_started',
      priority: task?.priority || 'normal',
      userId: task?.userId || '',
      description: task?.description || '',
      descriptionHtml: task?.descriptionHtml || '',
      dueDate: task?.dueDate || undefined,
      taskType: task?.taskType || 'tel',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().trim().required('必須項目です'),
      status: Yup.string().required('必須項目です'),
      priority: Yup.string().required('必須項目です'),
      taskType: Yup.string().required('必須項目です'),
      userId: Yup.string().nullable(),
      dueDate: Yup.date().nullable(),
      description: Yup.string().nullable(),
    }),
    onSubmit: (values) =>
      update({
        variables: {uuid: task.uuid, attributes: values},
      }),
  });

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (task.descriptionHtml) {
      formik.setFieldValue('descriptionHtml', task.descriptionHtml);
    } else {
      ref.current?.editor.setText(formik.values.description);
    }
  }, [visible]);

  return (
    <Modal title="ToDo編集" visible={visible} onClose={onClose}>
      <div className="flex flex-col px-8 py-4">
        <form onSubmit={formik.handleSubmit} className="">
          <div className="flex justify-center">
            <input
              type="text"
              placeholder="タイトル"
              value={formik.values.title}
              onChange={(e) => formik.setFieldValue('title', e.target.value)}
              className="form-input p-2 w-full border-c-border rounded-sm text-sm focus:outline-none"
              required
            />
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="action" className="w-24 mr-5 text-c-light">
              アクション種類
            </label>
            <select
              name="action"
              value={formik.values.taskType}
              onChange={(e) => formik.setFieldValue('taskType', e.target.value)}
              className="w-36 block form-select appearance-none bg-white border-c-border p-2 rounded-sm focus:outline-none text-sm text-c-light">
              <option value="tel">電話</option>
              <option value="mail">メール</option>
              <option value="other">その他</option>
            </select>
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="date" className="w-24 mr-5 text-c-light">
              日付
            </label>
            <input
              type="date"
              value={formik.values.dueDate}
              onChange={(date) =>
                formik.setFieldValue('dueDate', date.target.value)
              }
              className="w-36 mr-3 form-input border-c-border rounded-sm text-c-light text-sm focus:outline-none"
            />
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="user" className="w-24 mr-5 text-c-light">
              ToDo担当者
            </label>
            <Combobox
              value={user}
              onChange={(user) => {
                setUser(user);
                formik.setFieldValue('userId', user.id);
              }}>
              <Combobox.Button as={React.Fragment}>
                <div
                  className="relative mt-1 h-[2.5rem]"
                  onClick={() => inputRef.current?.focus()}>
                  <Combobox.Input
                    onChange={(e) => setUserName(e.target.value)}
                    onFocus={() => buttonRef.current?.click()}
                    value={userName}
                    displayValue={(user: User) =>
                      user && `${user.lastName} ${user.firstName}`
                    }
                    ref={inputRef}
                    className="relative w-full cursor-default rounded-sm bg-white py-2 pl-2 pr-10 flex items-center gap-1 border border-c-border focus:outline-none flex-wrap"
                  />
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                  <Transition
                    as={React.Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Combobox.Options className="absolute max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                      <Combobox.Option
                        className={({active}) =>
                          `relative cursor-default select-none py-2 px-4 ${
                            active ? 'bg-c-bg' : 'white'
                          }`
                        }
                        value={(): any => {
                          setUser(null);
                          formik.setFieldValue('userId', null);
                        }}>
                        <div className="flex items-center gap-2">
                          <span className="truncate flex-1 h-5" />
                        </div>
                      </Combobox.Option>
                      {filteredUsers.map((user) => (
                        <Combobox.Option
                          key={user.id}
                          className={({active}) =>
                            `relative cursor-default select-none py-2 px-4 ${
                              active ? 'bg-c-bg' : 'white'
                            }`
                          }
                          value={user}>
                          <div className="flex items-center gap-2">
                            <span className="truncate flex-1">
                              {user.lastName} {user.firstName}
                            </span>
                          </div>
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox.Button>
            </Combobox>
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="status" className="w-24 mr-5 text-c-light">
              ステータス
            </label>
            <select
              name="status"
              value={formik.values.status}
              onChange={(e) => formik.setFieldValue('status', e.target.value)}
              className="w-36 block form-select appearance-none bg-white border-c-border p-2 rounded-sm focus:outline-none text-sm text-c-light">
              {taskStatuses.map((status) => (
                <option key={status.value} value={status.value}>
                  {status.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="priority" className="w-24 mr-5 text-c-light">
              優先度
            </label>
            <select
              name="priority"
              value={formik.values.priority}
              onChange={(e) => formik.setFieldValue('priority', e.target.value)}
              className="w-36 block form-select appearance-none bg-white border-c-border p-2 rounded-sm focus:outline-none text-sm text-c-light">
              {taskPriorities.map((priority) => (
                <option key={priority.value} value={priority.value}>
                  優先度: {priority.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="priority" className="w-28 mr-7 text-c-light">
              説明
            </label>
            <QuillWrapper onBlur={formik.handleBlur('description_html')}>
              <Quill
                ref={ref}
                theme="snow"
                value={formik.values.descriptionHtml}
                onChange={(content, delta, source, editor) => {
                  formik.setFieldValue('descriptionHtml', content);
                  formik.setFieldValue('description', editor.getText());
                }}
                modules={{
                  toolbar: [
                    [{header: [1, 2, 3, false]}],
                    ['bold', 'italic', 'underline'],
                    ['code-block'],
                    ['link'],
                  ],
                }}
                formats={[
                  'header',
                  'list',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'color',
                  'background',
                  'code-block',
                  'link',
                ]}
                bounds={'#quill-container'}
              />
            </QuillWrapper>
          </div>
          <div className="flex justify-end my-5">
            <button
              type="button"
              onClick={onClose}
              className="cursor-pointer bg-white border border-c-border text-c-light text-sm hover:opacity-50 rounded items-center justify-center h-9 w-24 disabled:bg-c-bg disabled:text-c-light mr-3">
              キャンセル
            </button>
            <button
              type="submit"
              disabled={!formik.isValid || !formik.dirty}
              className="cursor-pointer bg-c-primary text-white border-none text-sm hover:opacity-50 rounded items-center justify-center h-9 w-24 disabled:bg-c-bg disabled:text-c-light disabled:cursor-default">
              保存
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const QuillWrapper = styled.div`
  width: 100%;
  border: 1px solid #e2e6ea;
  border-radius: 4px;
  .ql-container {
    border: none;
  }
  .ql-container.ql-snow {
    min-height: 50px;
    .ql-editor {
      min-height: 50px;
      overflow-y: scroll;
      resize: vertical;
    }
  }
`;
