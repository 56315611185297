import React from 'react';
import {Form} from 'api';
import IframeContent from './iframe';
import PageContent from './page';
import {Page} from 'react-pdf';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const [type, setType] = React.useState('iframe');

  return (
    <div className="flex-1 flex flex-col gap-8">
      <div>
        <h2 className="text-lg font-bold">フォームの設置方法の選択</h2>
        <p>フォームを使用する際、2種類の方法で設置が可能です。</p>
      </div>
      <div className="grid grid-cols-2 w-60">
        <button
          className={`border border-c-border rounded-l-lg h-8 bg-white cursor-pointer ${
            type === 'iframe' ? 'bg-white text-c-base' : 'bg-c-bg text-c-light'
          }`}
          onClick={() => setType('iframe')}>
          iframe方式
        </button>
        <button
          className={`border border-c-border border-l-0 rounded-r-lg h-8 cursor-pointer ${
            type === 'page' ? 'bg-white text-c-base' : 'bg-c-bg text-c-light'
          }`}
          onClick={() => setType('page')}>
          公開ページ
        </button>
      </div>

      <div>
        {type === 'iframe' && <IframeContent form={form} />}
        {type === 'page' && <PageContent form={form} />}
      </div>
    </div>
  );
};
