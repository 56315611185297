import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {useProjectQuery} from 'api';
import Action from './action';
import BlockGroup from './blockGroup';
import ProjectGroup from './projectGroup';
import Share from './share';
import Tag from './tag';
import ProjectInformation from './ProjectInformation';
import Content from './content';
import useProject from 'hooks/useProject';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {isOwnProject} = useProject(projectId);
  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  if (!project) return null;

  return (
    <Container>
      <div>
        <Action />
      </div>

      {isOwnProject && (
        <div>
          <Tag />
        </div>
      )}

      {isOwnProject && (
        <div>
          <ProjectGroup />
        </div>
      )}

      {project.status !== 'draft' && isOwnProject && (
        <div>
          <Share />
        </div>
      )}

      {project.status !== 'draft' && isOwnProject && (
        <div>
          <BlockGroup />
        </div>
      )}

      {project.status !== 'draft' && isOwnProject && (
        <div>
          <Content />
        </div>
      )}

      <div>
        <ProjectInformation />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;

  > div {
    border-bottom: 1px solid #e2e6ea;
    padding: 16px 0 20px 0;
    &:first-child {
      padding: 0px 0 20px 0;
    }
  }
`;
