import React from 'react';
import {Input, Checkbox, Form, Select} from 'components/antd';
import {SalesforceDealField, useSalesforceUsersQuery} from 'api';
import {FormikProps} from 'formik';
import {FormikValues} from '../DealToContactForm';
import useSalesforceModalContext from '../../../useSalesforceModalContext';

interface Props {
  formik: FormikProps<FormikValues>;
  field: SalesforceDealField;
}

const DealFieldItem = ({formik, field}: Props) => {
  const {poolId} = useSalesforceModalContext();

  const {data: {salesforceUsers = []} = {}} = useSalesforceUsersQuery({
    variables: {poolId: poolId},
    skip: !poolId,
  });

  return (
    <Form.Item label={field.label} required={field.isRequired}>
      {field?.fieldType === 'checkbox' ? (
        <Checkbox
          checked={formik.values.addition[`${field.fieldName}`] || false}
          onChange={(e) => {
            let addition = formik.values.addition;
            addition[`${field.fieldName}`] = e.target.checked;
            formik.setFieldValue('addition', addition);
          }}
        />
      ) : field?.fieldType === 'date' ? (
        <Input
          type="date"
          value={formik.values.addition[`${field.fieldName}`]}
          style={{width: '200px'}}
          onChange={(e) => {
            let addition = formik.values.addition;
            addition[`${field.fieldName}`] = e.target.value;
            formik.setFieldValue('addition', addition);
          }}
        />
      ) : field?.fieldType === 'select' ? (
        <Select
          value={formik.values.addition[`${field.fieldName}`]}
          onChange={(value) => {
            let addition = formik.values.addition;
            addition[`${field.fieldName}`] = value;
            formik.setFieldValue('addition', addition);
          }}
          showSearch
          optionFilterProp="children">
          {field.salesforceDealFieldSelectOptions.map(
            (option) =>
              option?.isActive && (
                <Select.Option key={option.id} value={option.value}>
                  {option.label}
                </Select.Option>
              ),
          )}
        </Select>
      ) : field?.fieldType === 'reference' &&
        field?.referenceType === 'user' ? (
        <Select
          value={formik.values.addition[`${field.fieldName}`]}
          onChange={(value) => {
            let addition = formik.values.addition;
            addition[`${field.fieldName}`] = value;
            formik.setFieldValue('addition', addition);
          }}
          showSearch
          optionFilterProp="children">
          {salesforceUsers.map((user) => (
            <Select.Option key={user.id} value={user.userId}>
              {user.name}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Input
          value={formik.values.addition[`${field.fieldName}`]}
          onChange={(e) => {
            let addition = formik.values.addition;
            addition[`${field.fieldName}`] = e.target.value;
            formik.setFieldValue('addition', addition);
          }}
        />
      )}
    </Form.Item>
  );
};

export default DealFieldItem;
