import {
  ReportTableData,
  ReportTableHeader,
} from '../../../components/Table/types';
import {useLeadSourceReportsQuery, useLeadSumReportQuery} from 'api';
import React from 'react';

const useTableData = () => {
  const headers: ReportTableHeader[] = ['リードソース', ''];
  const {data: {leadSourceReports = []} = {}} = useLeadSourceReportsQuery({});
  const {data: {leadSumReport = null} = {}} = useLeadSumReportQuery({});
  const sumReportData = React.useMemo(() => {
    if (!leadSumReport) {
      return;
    }
    return {
      label: leadSumReport.name,
      values: leadSumReport.values,
      callProps: {
        style: {
          borderBottom: 'double 3px #899098',
        },
      },
    };
  }, [leadSumReport]);

  const reportData = React.useMemo(() => {
    return leadSourceReports.map((report) => {
      return {
        label: report.name,
        values: report.values,
      };
    });
  }, [leadSourceReports]);

  const data: ReportTableData[] = [sumReportData, ...reportData].filter(
    Boolean,
  );
  return {headers, data};
};

export default useTableData;
