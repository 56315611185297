import React from 'react';
import {FieldValue} from './FieldValue';
import {SalesforceContact, usePoolQuery} from 'api';
import LinkIcon from '../LinkIcon';

interface Props {
  salesforceContact: SalesforceContact;
}

export default ({salesforceContact}: Props) => {
  const {data: {pool: {poolSalesforceSetting = {}} = {}} = {}} = usePoolQuery(
    {},
  );

  const url = poolSalesforceSetting?.instanceUrl?.replace(
    'my.salesforce.com',
    'lightning.force.com',
  );
  const fields =
    salesforceContact?.salesforceContactRecordType?.salesforceContactFields?.filter(
      (field) => field?.isDisplay,
    ) || [];

  if (!salesforceContact) {
    return null;
  }

  return (
    <div
      className={`flex w-full flex-col rounded-sm border border-c-border bg-white px-8`}>
      <div className="flex h-12 items-center gap-2 border-b border-b-c-border">
        <h3 className="m-0 text-base font-bold">{salesforceContact.name}</h3>
        <a
          target="_blank"
          className="h-full flex items-center"
          href={`${url}/lightning/r/Contact/${salesforceContact.contactId}/view`}>
          <LinkIcon />
        </a>
      </div>
      <div className="flex h-12 items-center gap-2 border-b border-b-c-border">
        <span className="text-c-light">レコードタイプ</span>
        <div>{salesforceContact.salesforceContactRecordType?.name}</div>
      </div>
      <div className="flex flex-col gap-2 py-4">
        {fields.map((field, i) => (
          <FieldValue
            field={field}
            key={i}
            salesforceContact={salesforceContact}
          />
        ))}
      </div>
    </div>
  );
};
