import React from 'react';
import styled from 'styled-components';
import GroupFilter from './Group';
import ProjectFilter from './Project';
import RequestClientFilter from './RequestClient';
import RequestProjectFilter from './RequestProject';
import DisplayRangeFilter from './DisplayRange';
import AssigneeFilter from './Assignee';

export default function () {
  return (
    <Container>
      <GroupFilter />
      <ProjectFilter />
      <RequestClientFilter />
      <RequestProjectFilter />
      <DisplayRangeFilter />
      <AssigneeFilter />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
