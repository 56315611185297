import React from 'react';
import moment from 'moment';
import {useForm, FormProvider} from 'react-hook-form';
import {Dialog, Transition} from '@headlessui/react';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  useCreateClientDownloadProjectMutation,
  useCsvDownloadableProjectColumnsQuery,
  Project,
  ProjectColumn,
} from 'api';
import * as Yup from 'yup';
import CheckboxSection from './CheckboxSection';

const companyOptionDataTypes = [
  'company_name',
  'corp_number',
  'web_site',
  'zip_code',
  'prefecture',
  'address',
  'fax_number',
  'telephone_number',
  'parent_categories',
  'child_categories',
  'mail_address',
  'representative_person',
  'employee_number',
  'offices_number',
  'capital_fund',
  'listing_market',
  'sales',
  'account_closing_month',
  'established_year_month',
];

const salesListOptionDataTypes = [
  'serial_number',
  'prelead_uuid',
  'user',
  'nayose',
  'tag',
];

const salesListActivityOptionDataTypes = [
  'status',
  'mail_updated_at',
  'mail_status',
  'click',
  'click_updated_at',
  'latest_tel_status',
  'latest_tel_user',
  'tel_status',
  'user_of_latest_tel_activity',
  'tel_count',
  'tel_updated_at',
  'latest_tel_comment',
  'latest_tel_comment_recorded_at',
  'remind',
  'latest_remind',
  'mail_campaign',
  'response_status',
];

const defaultOptionLabels = ['会社名', 'URL', 'メールアドレス', '電話番号'];

const sortOptions = (
  options: {value: string; label: string}[],
  values: string[],
) => {
  const optionValues = options.map((option) => option.value);
  const filteredValues = values.filter((value) => optionValues.includes(value));
  const sorted = filteredValues.sort((a, b) => {
    return optionValues.indexOf(a) - optionValues.indexOf(b);
  });
  return sorted;
};

interface Props {
  project: Project;
  visible: boolean;
  onClose: () => void;
}

const validationSchema = Yup.object().shape({
  filename: Yup.string().required('必須項目です'),
  columnIds: Yup.array().of(Yup.string()).min(1, '必須項目です'),
});

const DownloadModal = ({project, visible, onClose}: Props) => {
  const [completed, setCompleted] = React.useState(false);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      columnIds: [],
      filename: `${project?.name}-${moment().format('YYYYMMDD')}`,
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
    reset,
  } = methods;

  const {data: {csvDownloadableProjectColumns = []} = {}} =
    useCsvDownloadableProjectColumnsQuery({
      variables: {projectUuid: project.uuid},
      skip: !project,
      onCompleted: ({csvDownloadableProjectColumns}) => {
        const options = csvDownloadableProjectColumns.filter(
          (column) =>
            companyOptionDataTypes.includes(column.dataType) &&
            defaultOptionLabels.includes(column.csvHeaderName),
        );
        setValue(
          'columnIds',
          options.map((option) => option.id),
        );
      },
      fetchPolicy: 'network-only',
    });

  const castOptions = (csvDownloadableProjectColumns: ProjectColumn[]) => {
    return csvDownloadableProjectColumns.map((column) => {
      return {
        value: column.id,
        label:
          column.dataType?.match(/^customize_item_/) ||
          column.dataType?.match(/^client_prelead_customize_item_/) ||
          column.dataType.match(/^project_group_prelead_customize_item_/)
            ? column.title
            : column.csvHeaderName,
      };
    });
  };

  const companyOptions: {value: string; label: string}[] = castOptions(
    csvDownloadableProjectColumns.filter((column) => {
      return companyOptionDataTypes.includes(column.dataType);
    }),
  );

  const salesListOptions: {value: string; label: string}[] = castOptions(
    csvDownloadableProjectColumns.filter((column) => {
      return salesListOptionDataTypes.includes(column.dataType);
    }),
  );

  const salesListActivityOptions: {value: string; label: string}[] =
    castOptions(
      csvDownloadableProjectColumns.filter((column) => {
        return salesListActivityOptionDataTypes.includes(column.dataType);
      }),
    );

  const clientPreleadCustomizeItemColumnOptions: {
    value: string;
    label: string;
  }[] = castOptions(
    csvDownloadableProjectColumns.filter((column) => {
      return column.clientPreleadCustomizeItemId !== null;
    }),
  );

  const projectGroupPreleadCustomizeItemColumnOptions: {
    value: string;
    label: string;
  }[] = castOptions(
    csvDownloadableProjectColumns.filter((column) => {
      return column.projectGroupPreleadCustomizeItemId !== null;
    }),
  );

  const [createProjectDownload] = useCreateClientDownloadProjectMutation({
    onCompleted: () => setCompleted(true),
  });

  const onSubmit = (data: {filename: string; columnIds: string[]}) => {
    createProjectDownload({
      variables: {
        projectUuid: project.uuid,
        attributes: {
          filename: data.filename + '.csv',
          columnIds: [
            ...sortOptions(companyOptions, data.columnIds),
            ...sortOptions(salesListOptions, data.columnIds),
            ...sortOptions(salesListActivityOptions, data.columnIds),
            ...sortOptions(
              clientPreleadCustomizeItemColumnOptions,
              data.columnIds,
            ),
            ...sortOptions(
              projectGroupPreleadCustomizeItemColumnOptions,
              data.columnIds,
            ),
          ],
        },
      },
    });
  };

  React.useEffect(() => {
    reset({
      filename: `${project?.name}-${moment().format('YYYYMMDD')}`,
    });
  }, [visible]);

  return (
    <Transition appear show={visible} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {completed ? (
                  <div className="flex flex-col items-center my-8 gap-8 text-base">
                    <h2 className="text-lg font-bold">
                      ダウンロードファイルを作成しています
                    </h2>
                    <ul className="list-disc">
                      <li>
                        処理完了後、ファイルをダウンロードいただけます（完了後、メールで通知します）
                      </li>
                      <li>
                        件数が多い場合、完了までに時間がかかる場合があります
                      </li>
                    </ul>
                    <a
                      href="/downloads"
                      className="bg-c-primary text-white leading-10 h-10 px-6 rounded">
                      ダウンロード履歴を確認
                    </a>
                  </div>
                ) : (
                  <>
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-bold">
                      CSVダウンロード - 営業リスト
                    </Dialog.Title>
                    <FormProvider {...methods}>
                      <form
                        className="flex flex-col gap-4 my-4"
                        onSubmit={handleSubmit(onSubmit)}>
                        <div>
                          <div className="text-base inline-block bg-c-bg items-center p-4 rounded">
                            対象: {(project?.count || 0).toLocaleString()}件
                          </div>
                        </div>
                        <ul className="text-sm list-disc list-inside">
                          <li>
                            処理完了後、[ダウンロード履歴]
                            からファイルをダウンロードいただけます（完了後、メールで通知します）
                          </li>
                          <li>
                            件数が多い場合、完了までに時間がかかる場合があります
                          </li>
                        </ul>
                        <div className="flex flex-col gap-6">
                          <div className="flex flex-col gap-2">
                            <label className="text-base font-bold m-0">
                              ファイル名を入力
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="flex items-center gap-2">
                              <input
                                type="text"
                                className="form-input text-base block w-[240px] border border-c-border rounded placeholder-c-lighter"
                                {...register('filename')}
                              />
                              <span className="text-lg">.csv</span>
                            </div>
                            {errors.filename && (
                              <div className="text-red-500">
                                {errors.filename.message}
                              </div>
                            )}
                          </div>
                          <label className="text-base font-bold m-0">
                            ダウンロード項目を選択
                          </label>
                          {errors.columnIds && (
                            <div className="text-red-500">
                              {errors.columnIds.message}
                            </div>
                          )}
                          <CheckboxSection
                            title="会社情報"
                            options={companyOptions}
                          />
                          <CheckboxSection
                            title="営業リスト項目"
                            options={salesListOptions}
                          />
                          <CheckboxSection
                            title="営業リストアクティビティ"
                            options={salesListActivityOptions}
                          />

                          {clientPreleadCustomizeItemColumnOptions.length !==
                            0 && (
                            <CheckboxSection
                              title="カスタマイズ項目"
                              options={clientPreleadCustomizeItemColumnOptions}
                            />
                          )}

                          {projectGroupPreleadCustomizeItemColumnOptions.length !==
                            0 && (
                            <CheckboxSection
                              title="グループカスタマイズ項目"
                              options={
                                projectGroupPreleadCustomizeItemColumnOptions
                              }
                            />
                          )}
                          <div className="flex flex-row-reverse">
                            <button
                              type="submit"
                              className="cursor-pointer bg-c-primary text-white border-none text-sm hover:opacity-50 rounded items-center justify-center h-9 w-32 disabled:bg-c-bg disabled:text-c-light">
                              ダウンロード
                            </button>
                          </div>
                        </div>
                      </form>
                    </FormProvider>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DownloadModal;
