import React from 'react';
import Modal from 'components/Prospect/Task/Modal';
import {useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  useUsersByClientAllQuery,
  useCreatePreleadTaskMutation,
  usePreleadProjectQuery,
  TaskAttributes,
} from 'api';
import {taskPriorities, taskStatuses} from 'helpers/task';
import {Quill} from 'components/Ui';

interface Props {
  visible: boolean;
  onClose: () => void;
}

export default ({visible, onClose}: Props) => {
  const {preleadProjectId} = useParams<{
    preleadProjectId: string;
  }>();

  const {data: {preleadProject: {prelead = null, project = null} = {}} = {}} =
    usePreleadProjectQuery({
      variables: {uuid: preleadProjectId},
      skip: !preleadProjectId,
    });

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  const [createTask] = useCreatePreleadTaskMutation({
    onCompleted: () => {
      onClose();
    },
    refetchQueries: ['preleadTasks'],
  });

  const ref = React.useRef(null);

  const onSubmit = (values: TaskAttributes) =>
    createTask({
      variables: {
        uuid: prelead.uuid,
        projectUuid: project.uuid,
        attributes: values,
      },
    });

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        title: Yup.string().trim().required('必須項目です'),
        priority: Yup.string().required('必須項目です'),
        status: Yup.string().required('必須項目です'),
        taskType: Yup.string().required('必須項目です'),
        userId: Yup.string().nullable(),
        dueDate: Yup.date().nullable(),
        description: Yup.string().nullable(),
        descriptionHtml: Yup.string().nullable(),
      }),
    ),
    defaultValues: {
      title: '',
      priority: 'normal',
      userId: '',
      description: '',
      descriptionHtml: '',
      dueDate: undefined,
      taskType: 'tel',
      status: 'not_started',
    },
    mode: 'onChange',
  });

  return (
    <Modal title="ToDo" visible={visible} onClose={onClose}>
      <div className="flex flex-col px-8 py-4">
        <form onSubmit={handleSubmit(onSubmit)} className="">
          <div className="flex flex-col gap-1">
            <input
              type="text"
              placeholder="タイトル"
              className="form-input p-2 w-full border-c-border rounded-sm text-sm focus:outline-none"
              {...register('title')}
            />
            {errors?.title && (
              <div className="text-red-500">{errors.title.message}</div>
            )}
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="action" className="w-24 mr-5 text-c-light">
              アクション種類
            </label>
            <select
              {...register('taskType')}
              className="w-36 block form-select appearance-none bg-white border-c-border p-2 rounded-sm focus:outline-none text-sm text-c-light">
              <option value="tel">電話</option>
              <option value="mail">メール</option>
              <option value="other">その他</option>
            </select>
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="date" className="w-24 mr-5 text-c-light">
              日付
            </label>
            <input
              type="date"
              {...register('dueDate')}
              className="w-36 mr-3 form-input border-c-border rounded-sm text-c-light text-sm focus:outline-none"
            />
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="priority" className="w-24 mr-5 text-c-light">
              優先度
            </label>
            <select
              {...register('priority')}
              className="w-36 block form-select appearance-none bg-white border-c-border p-2 rounded-sm focus:outline-none text-sm text-c-light">
              {taskPriorities.map((priority) => (
                <option key={priority.value} value={priority.value}>
                  優先度: {priority.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="status" className="w-24 mr-5 text-c-light">
              ステータス
            </label>
            <select
              {...register('status')}
              className="w-36 block form-select appearance-none bg-white border-c-border p-2 rounded-sm focus:outline-none text-sm text-c-light">
              {taskStatuses.map((status) => (
                <option key={status.value} value={status.value}>
                  {status.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="user" className="w-32 mr-5 text-c-light">
              ToDo担当者
            </label>
            <select
              {...register('userId')}
              className="w-full block form-select appearance-none bg-white border-c-border p-2 rounded-sm focus:outline-none text-sm text-c-light">
              <option value="">未選択</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.lastName} {user.firstName}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="priority" className="w-28 mr-7 text-c-light">
              説明
            </label>
            <div
              className="w-full border border-c-border rounded [&_.ql-container]:border-none
    [&_.ql-snow]:min-h-[50px] [&_.ql-editor]:min-h-[50px] [&_.ql-editor]:overflow-y-scroll [&_.ql-editor]:resize-vertical">
              <Quill
                ref={ref}
                theme="snow"
                onChange={(content, delta, source, editor) => {
                  setValue('descriptionHtml', content);
                  setValue('description', editor.getHTML());
                }}
                modules={{
                  toolbar: [
                    [{header: [1, 2, 3, false]}],
                    ['bold', 'italic', 'underline'],
                    ['code-block'],
                    ['link'],
                  ],
                }}
                formats={[
                  'header',
                  'list',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'color',
                  'background',
                  'code-block',
                  'link',
                ]}
                bounds={'#quill-container'}
              />
            </div>
          </div>
          <div className="flex justify-end my-5">
            <button
              type="button"
              onClick={onClose}
              className="cursor-pointer bg-white border border-c-border text-c-light text-sm hover:opacity-50 rounded items-center justify-center h-9 w-24 disabled:bg-c-bg disabled:text-c-light mr-3">
              キャンセル
            </button>
            <button
              type="submit"
              className="cursor-pointer bg-c-primary text-white border-none text-sm hover:opacity-50 rounded items-center justify-center h-9 w-24 disabled:bg-c-bg disabled:text-c-light disabled:cursor-default">
              登録
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
