import {
  ReportTableData,
  ReportTableHeader,
} from '../../../components/Table/types';
import {useUserSumReportQuery, useLeadSourceUserReportsQuery} from 'api';
import useUserHeaders from './useUserHeaders';

const useTableData = () => {
  const {data: {userSumReport = null} = {}} = useUserSumReportQuery({});
  const {data: {leadSourceUserReports = []} = {}} =
    useLeadSourceUserReportsQuery({});

  const sumValues = (values: number[]): number => {
    const total = values
      .filter(Boolean)
      .reduce((accumulator, currentValue) => accumulator + currentValue, null);
    return total;
  };

  const headers: ReportTableHeader[] = useUserHeaders();

  const data: ReportTableData[] = [userSumReport, ...leadSourceUserReports]
    .filter(Boolean)
    .map((report) => {
      return {
        label: report.name,
        values: [sumValues(report.values), ...report.values],
      };
    });
  return {data, headers};
};

export default useTableData;
