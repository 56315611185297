import React from 'react';
import {AccountPool} from 'api';
import {Row} from 'react-table';
import LinkIcon from './LinkIcon';
import CheckIcon from './CheckIcon';

interface Props {
  row: Row<AccountPool>;
}

const NameCell = ({row}: Props) => {
  const {account} = row.original;

  return (
    <div className="flex flex-col gap-1 justify-center h-full">
      <h4 className="text-base font-medium m-0 leading-none">
        {account.name}
        {account.companyId && <CheckIcon className="ml-1 relative top-[2px]" />}
      </h4>
      <a
        target="_blank"
        href={account.webSite}
        className="text-xs text-c-light">
        {account.address || '住所不明'}
        {account.webSite && (
          <LinkIcon className="ml-1 relative text-[#BDC4CB] top-[3px]" />
        )}
      </a>
    </div>
  );
};

export default NameCell;
