import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {useCurrentUserQuery} from 'api';

export default () => {
  const {data: {currentUser = null} = {}} = useCurrentUserQuery();

  const inActiveStyle = 'text-[#BDC4CB]';
  const activeStyle = 'text-[#000] border-b-4 border-[#495058] font-bold';

  return (
    <HeadSection>
      <Link
        to="/settings/externals/salesforce"
        className={
          location.pathname.endsWith('salesforce') ? activeStyle : inActiveStyle
        }>
        <div className="pb-4 text-base">連携設定</div>
      </Link>
      <Link
        to="/settings/externals/salesforce/sync_target"
        className={
          location.pathname.endsWith('salesforce/sync_target')
            ? activeStyle
            : inActiveStyle
        }>
        <div className="pb-4 text-base">同期対象設定</div>
      </Link>
      {currentUser?.role === 'admin' && (
        <Link
          to="/settings/externals/salesforce/account"
          className={
            location.pathname.endsWith('account') ? activeStyle : inActiveStyle
          }>
          <div className="pb-4 text-base">取引先</div>
        </Link>
      )}
      <Link
        to="/settings/externals/salesforce/todo"
        className={
          location.pathname.endsWith('todo') ? activeStyle : inActiveStyle
        }>
        <div className="pb-4 text-base">ToDo</div>
      </Link>
      <Link
        to="/settings/externals/salesforce/task"
        className={
          location.pathname.endsWith('task') ? activeStyle : inActiveStyle
        }>
        <div className="pb-4 text-base">活動の記録</div>
      </Link>
    </HeadSection>
  );
};

const HeadSection = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  border-bottom: 1px solid #e2e6ea;
  margin-top: 14px;
  margin-bottom: 36px;
`;
