import React from 'react';
import {Step, CallStep, useWorkflowQuery} from 'api';
import Stats from './Stats';
import {useParams, Link} from 'react-router-dom';
import StepTypeIcon from 'components/Workflow/Icon/StepTypeIcon';
import Components from '../Components';

interface CallStepType extends Step {
  steppable: CallStep;
}

interface Props {
  step: CallStepType;
}

const CallStats = ({step}: Props) => {
  const {workflowId, poolId} = useParams<{
    workflowId: string;
    poolId: string;
  }>();
  const {data: {workflow = null} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
  });

  return (
    <div className="flex items-center w-full">
      <Components.Switch step={step} />
      <Components.Date step={step} />
      <div className="mx-2 h-8">
        <StepTypeIcon step={step} active width="32px" height="32px" />
      </div>
      <div className="flex flex-col min-w-0 max-w-md">
        <div>
          <Link
            className="overflow-hidden text-ellipsis whitespace-nowrap font-bold text-base mr-1"
            to={`/pools/${poolId}/workflows/${workflowId}/steps/${step.uuid}`}>
            {step.name}
          </Link>
        </div>
        <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm text-c-light m-0">
          {step.memo}
        </p>
      </div>
      <div className="flex justify-end items-center flex-1">
        <Stats step={step} />
        {workflow.status === 'draft' && <Components.Action step={step} />}
      </div>
    </div>
  );
};

export default CallStats;
