import React from 'react';
import {FieldValue} from './FieldValue';
import {SalesforceLead, usePoolQuery, SalesforceLeadField} from 'api';
import LinkIcon from '../LinkIcon';

interface Props {
  salesforceLead: SalesforceLead;
}

export default ({salesforceLead}: Props) => {
  const {data: {pool: {poolSalesforceSetting = {}} = {}} = {}} = usePoolQuery(
    {},
  );

  const url = poolSalesforceSetting?.instanceUrl?.replace(
    'my.salesforce.com',
    'lightning.force.com',
  );
  const fields =
    salesforceLead?.salesforceLeadRecordType?.salesforceLeadFields?.filter(
      (field: SalesforceLeadField) => field?.isDisplay,
    ) || [];

  if (!salesforceLead) {
    return null;
  }

  return (
    <div
      className={`flex w-full flex-col rounded-sm border border-c-border bg-white px-8`}>
      <div className="flex h-12 items-center gap-2 border-b border-b-c-border">
        <h3 className="m-0 text-base font-bold">
          {salesforceLead.lastName} {salesforceLead.firstName}
        </h3>
        <a
          target="_blank"
          className="h-full flex items-center"
          href={`${url}/lightning/r/Lead/${salesforceLead.leadId}/view`}>
          <LinkIcon />
        </a>
      </div>
      <div className="flex h-12 items-center gap-2 border-b border-b-c-border">
        <span className="text-c-light">レコードタイプ</span>
        <div>{salesforceLead.salesforceLeadRecordType?.name}</div>
      </div>
      <div className="flex flex-col gap-2 py-4">
        {fields.map((field, i) => (
          <FieldValue field={field} key={i} salesforceLead={salesforceLead} />
        ))}
      </div>
    </div>
  );
};
