import styled from 'styled-components';
import {Checkbox} from 'components/antd';

const PopoverContent = styled.div`
  width: 299px;
  padding: 24px;
  margin-left: 0px;
  max-height: 480px;
  overflow-y: auto;
  cursor: pointer;
  :hover {
    color: #2693ff;
  }
  &&& {
    .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
  .ant-radio-pool {
    display: flex;
    flex-direction: column;
  }
`;

const CheckBoxStyled = styled(Checkbox)`
  font-size: 12px;
  font-weight: 500;
  color: #495058;
  display: flex;
  gap: 4px;
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .ant-checkbox + span {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  &&& {
    .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
`;

const Box = styled.div`
  display: flex;
  width: 100%;
  height: 42px;
  margin-right: 8px;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
  color: #899098;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #e1e6eb;
  cursor: pointer;

  &:last-child {
    margin-right: 0px;
  }

  span {
    width: 26px;
    height: 18px;
    background: #e2f1ff;
    color: #68b5fb;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :hover {
    color: #2693ff;
  }
  .ant-picker {
    border: none;
    padding: 0px;
    width: 100%;
    box-shadow: none;
    span {
      background: none;
      color: #899098;
    }
  }
  .ant-picker-input > input {
    font-size: 12px;
    color: #899098;
    font-weight: bold;
  }

  .ant-picker-range {
    .ant-picker-input {
      input {
        font-size: 11px;
      }
    }
  }
`;

const FilterContent = styled.div`
  padding: 28px 12px;
  width: 217px;
  color: #495058;
  font-size: 12px;
  max-height: 240px;
`;

export {PopoverContent, CheckBoxStyled, Box, FilterContent};
