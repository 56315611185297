import React, {useMemo} from 'react';
import {SalesforceAccount, SalesforceAccountField} from 'api';

interface Props {
  field: SalesforceAccountField;
  salesforceAccount: SalesforceAccount;
}

export const FieldValue: React.FC<Props> = ({field, salesforceAccount}) => {
  const Value = useMemo(() => {
    const value = salesforceAccount.addition[`${field.fieldName}`];

    if (field.fieldType === 'checkbox') {
      return (
        <input
          className="cursor-pointer"
          type="checkbox"
          name={field.label}
          checked={value}
          readOnly
        />
      );
    } else if (field.fieldType === 'address') {
      const hash = value;
      if (!hash) {
        return null;
      }
      const address = `${hash?.state}${hash?.city}${hash?.street}`;
      return <div>{address}</div>;
    } else {
      return <div>{value}</div>;
    }
  }, [field, salesforceAccount.addition]);

  return (
    <div className="flex px-4 align-middle text-[#495058]">
      <div className="w-2/5 text-sm ">{field.label}</div>
      <div className="w-3/5">{Value}</div>
    </div>
  );
};
