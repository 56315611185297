import gql from 'graphql-tag';

export default gql`
  fragment mailCampaignFragment on MailCampaign {
    id
    uuid
    status
    statusText
    target
    targetText
    title
    subject
    fromAddress
    fromName
    cc
    bcc
    sendAt
    isImmediately
    snippet
    backgroundColor
    bodyColor
    textColor
    bodyHtml
    mailCampaignSendsCount
    mailCampaignSendsClickedCount
    mailCampaignSendsOpenedCount
    mailCampaignSendsBouncedCount
    mailCampaignSendsUnsubscribedCount
    updatedAt
    createdAt
    pool {
      id
      uuid
    }
  }
`;
