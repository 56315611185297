import React from 'react';
import {ProspectPool} from 'api';
import Detail from './Detail';
import {Setting} from 'components/Ui/Icon';
import useClientUser from 'hooks/useClientUser';
import {useHistory} from 'react-router';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const history = useHistory();
  const {isMember} = useClientUser();

  return (
    <>
      <div className="flex w-full flex-col gap-6">
        <div className="flex items-center justify-end">
          {isMember && (
            <button
              onClick={() => history.push('/account/settings')}
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded border border-c-border bg-white text-c-light"
            >
              <Setting className="h-4 w-auto" />
            </button>
          )}
        </div>
        <Detail
          salesforceAccount={prospectPool?.salesforceContact?.salesforceAccount}
        />
      </div>
    </>
  );
};
