import React from 'react';
import useChartData from './useChartData';
import useTableData from './useTableData';
import {VerticalBarChart} from '../../components/Chart';
import {Table} from '../../components/Table';
import {Dropdown} from '../../components/Dropdown';
import {useCurrentUserQuery} from 'api';

export default () => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery({});
  const chart = useChartData();
  const table = useTableData();
  const [selected, setSelected] = React.useState('total');
  const dropdownItems = [
    {label: '合計', value: 'total'},
    {label: 'リードソース別', value: 'leadSource'},
    {label: '担当者別', value: 'user'},
  ];
  const chartHeight = React.useMemo(() => {
    return chart.axis.length * 60;
  }, [chart]);

  return (
    <div className="flex flex-col">
      <div className="border border-c-border">
        <VerticalBarChart
          chart={chart}
          legend={false}
          style={{height: `${chartHeight}px`}}
          yAxisProps={{width: 240}}
        />
        <div className="p-8 border-t border-c-border flex flex-col gap-2">
          {currentUser?.role === 'admin' && (
            <Dropdown
              value={selected}
              items={dropdownItems}
              onSelect={setSelected}
            />
          )}
          <Table table={table} />
        </div>
      </div>
    </div>
  );
};
