import React from 'react';
import useSearchParams from '../useSearchParams';
import {useCalledOfferedProjectsQuery} from 'api';
import {Popover} from 'components/antd';
import {PopoverContent, CheckBoxStyled, Box, FilterContent} from './style';

const Request = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {calledOfferedProjects: offeredProjects = []} = {}} =
    useCalledOfferedProjectsQuery({
      variables: {
        requestClientUuid: query.requestClientId,
      },
      fetchPolicy: 'cache-and-network',
    });

  const offeredProjectChecked = (id: string) =>
    query.offeredProjectIds.some((project) => project === id);

  const onChangeOfferedProject = React.useCallback((value: string) => {
    const newProjects = query.offeredProjectIds;
    const target = newProjects.find((project) => {
      return project === value;
    });
    if (target) {
      newProjects.splice(newProjects.indexOf(target), 1);
    } else {
      newProjects.push(value);
    }
    setQuery({offeredProjectIds: newProjects});
  }, []);

  const offeredProjectName = React.useCallback(
    (projectId: string) =>
      offeredProjects.find((project) => project.id === projectId)?.name,
    [offeredProjects],
  );

  return (
    <Popover
      placement="bottom"
      trigger="click"
      content={
        <PopoverContent>
          {offeredProjects.length === 0 && <span>データがありません。</span>}
          {offeredProjects?.map((project) => (
            <div key={project.id}>
              <CheckBoxStyled
                value={project.id}
                checked={offeredProjectChecked(project.id)}
                onChange={(e) => onChangeOfferedProject(e.target.value)}>
                {project.name}
              </CheckBoxStyled>
            </div>
          ))}
        </PopoverContent>
      }>
      <Box>
        依頼元営業リスト
        {query.offeredProjectIds.length > 0 && (
          <Popover
            placement="bottom"
            content={
              <FilterContent>
                {query.offeredProjectIds?.map((projectId) => (
                  <div key={projectId}>
                    <span>{offeredProjectName(projectId)}</span>
                  </div>
                ))}
              </FilterContent>
            }>
            {query.offeredProjectIds.length > 0 && (
              <span>{query.offeredProjectIds.length}</span>
            )}
          </Popover>
        )}
      </Box>
    </Popover>
  );
};

export default Request;
