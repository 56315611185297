import React from 'react';
import moment from 'moment';
import {Input, Form, DatePicker, Select} from 'components/antd';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  useCreateSalesforceDealSalesforceContactMutation,
  useSalesforceDealRecordTypesQuery,
  useSalesforceUsersQuery,
  useSalesforceOpportunityStagesQuery,
  ProspectPool,
  SalesforceDealField,
} from 'api';
import locale from 'antd/es/date-picker/locale/ja_JP';
import useSalesforceModalContext from '../../../useSalesforceModalContext';
import Back from '../../Back';
import Footer from '../../Footer';
import {useHistory} from 'react-router-dom';
import DealFieldItem from './DealFieldItem';

interface Props {
  prospectPool: ProspectPool;
}

export interface FormikValues {
  name: string;
  salesforceUserId: string;
  salesforceOpportunityStageId: string;
  closeDate: moment.Moment;
  salesforceDealRecordTypeId: string;
  addition: {[key: string]: any};
}

const DealToContactForm = ({prospectPool = {}}: Props) => {
  const history = useHistory();
  const [selectedRecordType, setSelectedRecordType] = React.useState(null);

  const {poolId} = useSalesforceModalContext();

  const {data: {salesforceUsers = []} = {}} = useSalesforceUsersQuery({
    variables: {poolId: poolId},
    skip: !poolId,
  });

  const {data: {salesforceOpportunityStages = []} = {}} =
    useSalesforceOpportunityStagesQuery({
      variables: {poolId: poolId},
      skip: !poolId,
    });

  const {data: {salesforceDealRecordTypes = []} = {}} =
    useSalesforceDealRecordTypesQuery({
      variables: {poolId: poolId},
      skip: !poolId,
    });

  const [create, {loading}] = useCreateSalesforceDealSalesforceContactMutation({
    onCompleted: (data) => {
      if (!data.createSalesforceDealSalesforceContact.error) {
        formik.resetForm();
      }
      history.goBack();
    },
    refetchQueries: ['prospectPool'],
  });

  const today = React.useMemo(() => moment(), []);

  const initialValues: FormikValues = {
    name: '',
    salesforceUserId: salesforceUsers.length > 0 ? salesforceUsers[0].id : '',
    salesforceOpportunityStageId:
      salesforceOpportunityStages.length > 0
        ? salesforceOpportunityStages[0].id
        : '',
    closeDate: today,
    salesforceDealRecordTypeId: '',
    addition: {},
  };

  const formik = useFormik<FormikValues>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
      salesforceUserId: Yup.string().required('必須項目です'),
      salesforceOpportunityStageId: Yup.string().required('必須項目です'),
      closeDate: Yup.date().required('必須項目です'),
    }),
    onSubmit: (values) => {
      const copy = Object.assign({}, values);
      create({variables: {uuid: prospectPool.uuid, attributes: copy}});
    },
  });

  const onRecordTypeSelect = (id: string) => {
    const selectedRecordType = salesforceDealRecordTypes.find(
      (recordType) => recordType.id === id,
    );
    formik.setFieldValue('addition', {});
    setSelectedRecordType(selectedRecordType);
  };

  const salesforceDealFields = React.useMemo(() => {
    if (!selectedRecordType) {
      return [];
    }
    const visibleFields = selectedRecordType?.salesforceDealFields.filter(
      (salesforceDealField: SalesforceDealField) =>
        salesforceDealField.isDisplay === true,
    );

    return visibleFields;
  }, [selectedRecordType]);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="relative w-full flex-1 overflow-y-auto px-8 py-6">
        <Back label="商談" />
        <div className="mt-4 w-full rounded bg-white p-4">
          <Form layout="vertical">
            {salesforceDealRecordTypes.length > 0 && (
              <>
                <Form.Item label="レコードタイプ">
                  <Select
                    onChange={(value: string) => {
                      formik.setFieldValue('salesforceDealRecordTypeId', value);
                      onRecordTypeSelect(value);
                    }}>
                    {salesforceDealRecordTypes.map((recordType) => (
                      <Select.Option key={recordType.id} value={recordType.id}>
                        {recordType.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <hr className="my-8 border-b border-c-border" />
              </>
            )}

            <Form.Item
              label="商談名"
              required
              validateStatus={formik.errors.name && 'error'}
              help={formik.errors.name}>
              <Input {...formik.getFieldProps('name')} />
            </Form.Item>

            <Form.Item
              label="所有者"
              required
              validateStatus={formik.errors.salesforceUserId && 'error'}
              help={formik.errors.salesforceUserId}>
              <Select
                value={formik.values.salesforceUserId}
                showSearch
                optionFilterProp="children"
                onChange={(value) =>
                  formik.setFieldValue('salesforceUserId', value)
                }>
                {salesforceUsers.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="フェーズ"
              required
              validateStatus={
                formik.errors.salesforceOpportunityStageId && 'error'
              }
              help={formik.errors.salesforceOpportunityStageId}>
              <Select
                value={formik.values.salesforceOpportunityStageId}
                onChange={(value) =>
                  formik.setFieldValue('salesforceOpportunityStageId', value)
                }>
                {salesforceOpportunityStages.map((stage) => (
                  <Select.Option key={stage.id} value={stage.id}>
                    {stage.masterLabel}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="完了予定日"
              required
              validateStatus={formik.errors.closeDate && 'error'}
              help={formik.errors.closeDate}>
              <DatePicker
                value={formik.values.closeDate}
                locale={locale}
                style={{width: '200px'}}
                onChange={(date) => formik.setFieldValue('closeDate', date)}
              />
            </Form.Item>

            {salesforceDealFields.map(
              (field: SalesforceDealField, i: number) => (
                <DealFieldItem key={i} field={field} formik={formik} />
              ),
            )}
          </Form>
        </div>
      </div>
      <Footer
        onClick={() => formik.submitForm()}
        label="登録"
        disabled={loading || !formik.isValid || !formik.dirty}
      />
    </div>
  );
};

export default DealToContactForm;
