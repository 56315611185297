import React from 'react';
import useSearchParams from '../useSearchParams';
import {useCurrentClientQuery} from 'api';
import {Popover, Radio} from 'components/antd';
import {PopoverContent, Box, FilterContent} from './style';

const RequestClientFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {currentClient: {requestActiveClients = []} = {}} = {}} =
    useCurrentClientQuery();

  const requestClientName = React.useCallback(
    (clientId: string) =>
      requestActiveClients.find((client) => client.uuid === clientId)?.name,
    [requestActiveClients],
  );

  return (
    <Popover
      placement="bottom"
      trigger="click"
      content={
        <PopoverContent>
          {requestActiveClients.length === 0 ? (
            <span>データがありません。</span>
          ) : (
            <Radio.Group
              value={query.requestClientId}
              onChange={(e) => setQuery({requestClientId: e.target.value})}>
              <Radio defaultChecked={true} value={undefined}>
                選択なし
              </Radio>
              {requestActiveClients?.map((client) => (
                <Radio key={client.uuid} value={client.uuid}>
                  {client.name}
                </Radio>
              ))}
            </Radio.Group>
          )}
        </PopoverContent>
      }>
      <Box>
        依頼元
        {query.requestClientId && (
          <Popover
            placement="bottom"
            content={
              <FilterContent>
                <div>
                  <span>{requestClientName(query.requestClientId)}</span>
                </div>
              </FilterContent>
            }>
            {query.requestClientId && <span>1</span>}
          </Popover>
        )}
      </Box>
    </Popover>
  );
};

export default RequestClientFilter;
